.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
    margin-top: 50px;
  }
  
  .contact-heading {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-content {
    display: flex;
    align-items: center;
  }
  
  .contact-info {
    flex: 1;
    margin-right: 50px;
  }
  
  .contact-subheading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .contact-details {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 40px;
  }
  
  .contact-details li {
    margin-bottom: 10px;
  }
  
  .contact-form {
    flex: 1;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #ff9800;
  }
  
  @media (max-width: 600px) {
    .contact-content {
      flex-direction: column;
    }
  
    .contact-info,
    .contact-form {
      margin-bottom: 40px;
    }
  }
  