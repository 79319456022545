.about-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
    margin-top: 50px;
  }
  
  .about-heading {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-content {
    display: flex;
    align-items: center;
  }
  
  .about-image {
    flex: 1;
    text-align: center;
  }
  
  .profile-image {
    max-width: 50%;
    height: auto;
    border-radius: 10px;
  }
  
  .about-description {
    flex: 1;
  }
  
  .about-subheading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .about-specialties ul {
    list-style-type: none;
    padding: 0;
  }
  
  .about-specialties li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
  }
  
  .about-specialties li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: #333;
  }
  
  .education-heading {
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
  }
  
  .education-details {
    margin-top: 20px;
  }
  
  .education-details div {
    margin-bottom: 20px;
  }
  
  .education-details h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .education-details p {
    margin-bottom: 10px;
  }
  
  .education-details ul {
    list-style-type: none;
    padding-left: 20px;
  }
  
  .education-details li {
    position: relative;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  .education-details li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: #333;
    margin-left: -15px;
  }

  @media (max-width: 600px) {
    .about-content {
      flex-direction: column;
    }
  
    .about-image {
      margin-bottom: 20px;
    }

    .education-details {
      margin-top: 10px;
    }
  }
  