.footer-container {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-links {
    margin-bottom: 20px;
  }
  
  .footer-link {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .footer-social-icons {
    margin-bottom: 20px;
  }
  
  .footer-icon-link {
    color: #fff;
    font-size: 24px;
    margin: 0 5px;
  }
  
  .footer-text {
    font-size: 14px;
  }
  