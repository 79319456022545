.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f1f1f1;
  }
  
  .home-content {
    text-align: center;
  }
  
  .my-photo {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    margin: 20px 0;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }

  h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .highlighted-text {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }  

  .more-info-link {
    display: inline-block;
    background-color: #ff6b6b;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .more-info-link:hover {
    background-color: #ff4f4f;
  }
  
  .social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .social-links .social-icon {
    font-size: 24px;
    color: #555555;
  }

