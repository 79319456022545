.gallery-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
    margin-top: 50px;
  }
  
  .gallery-row {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .gallery-item {
    flex: 0 0 calc(33.33% - 10px);
    position: relative;
    overflow: hidden;
  }
  
  .gallery-item img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  
/* Media query for mobile responsiveness */
@media (max-width: 768px) {
    .gallery-row {
      flex-direction: column;
    }
  
    .gallery-item {
      flex-basis: 100%;
      margin-bottom: 20px;
    }
  }
