.navbar {
  background-color: #000;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 18px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-logo a {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
}

.navbar-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-item {
  margin-left: 20px;
}

.navbar-link {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-link:hover {
  color: #ddd;
}

.navbar-mobile-menu {
  display: none;
  cursor: pointer;
}

.menu-icon {
  color: #fff;
  font-size: 24px;
}

@media (max-width: 768px) {
  .navbar-menu {
    display: none;
  }

  .navbar-item {
    /* margin: 10px 0; */
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 10px;
  }

  .navbar-mobile-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar-mobile-menu.open {
    position: absolute;
    top: 100%;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    padding: 10px;
    border-radius: 50%;
    z-index: 999;
  }

  .navbar-mobile-menu.open .menu-icon {
    color: #fff;
  }

  .navbar-menu.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #000;
    padding: 20px;
    width: 100%;
    z-index: 998;
  }

  .navbar-menu.open li {
    margin-bottom: 10px;
  }

  .navbar-menu.open .navbar-link {
    color: #fff;
  }
}
